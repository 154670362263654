<template>
  <div class="px-5 text-center">
    <div class="mb-10">
      <IcoContractRejected />
    </div>

    <v-card class="mx-auto mb-10" :elevation="elevation" max-width="45rem">
      <v-card-title>
        <div class="static__layout--title display-1 mx-auto mt-5">
          {{ $t('signing.signature_inserted') }}
        </div>
      </v-card-title>

      <v-card-text>
        <p class="subtitle-1 my-5">
          {{ $t('signing.can_close_browser_window') }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import IcoContractRejected from '@/components/svg/IcoContractRejected.vue'

export default {
  name: 'SuccessSignSms',
  components: {
    IcoContractRejected
  },
  computed: {
    elevation () {
      return this.$vuetify.breakpoint.mobile ? 0 : 2
    }
  }
}
</script>

<style lang="scss" scoped>
.static__layout--title {
  word-break: break-word;
}
</style>
